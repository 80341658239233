import { useMsal } from '@azure/msal-react';
//import { GDPRIcon } from 'assets/icons/GDPRIcon';
import { HelpAndContactIcon } from 'assets/icons/HelpAndContactIcon';
import { HelpAndContactSidePanel } from 'components/HelpAndContactSidePanel/HelpAndContactSidePanel';
import { Popover } from 'components/Popover/Popover';
import { KeyboardEvent, RefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import { GDPRSidePanel } from './GDPRSidePanel/GDPRSidePanel';
import {
  DisconnectText,
  MenuContainer,
  MenuText,
  StyledLogOutIcon,
  StyledMenuItem,
  StyledMenuList,
} from './UserCardPopover.style';

export const USER_CARD_POPOVER_MENU_ARIA_CONTROLS = 'user-card-popover-list-collapse';

interface Props {
  isOpen: boolean;
  close: () => void;
  anchorRef: RefObject<HTMLDivElement>;
}

export const UserCardPopover = ({ isOpen, close, anchorRef }: Props) => {
  const { instance } = useMsal();
  const userCardPopoverIntl = useIntlByKey('header.user-card.popover');

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      close();
    }
  };
  const [isHelpAndContactSidePanelOpened, setIsHelpAndContactSidePanelOpened] = useState(false);
  const [isGDPRSidePanelOpened, setIsGDPRSidePanelOpened] = useState(false);

  const logOut = () => instance.logoutRedirect();

  return (
    <>
      <Popover isOpen={isOpen} close={close} anchorRef={anchorRef}>
        <MenuContainer>
          <StyledMenuList id={USER_CARD_POPOVER_MENU_ARIA_CONTROLS} onKeyDown={handleListKeyDown}>
            <StyledMenuItem onClick={() => setIsHelpAndContactSidePanelOpened(true)} disableGutters>
              <HelpAndContactIcon widthInPx={18} heightInPx={18} />
              <MenuText>
                <FormattedMessage id={userCardPopoverIntl('help-and-contact')} />
              </MenuText>
            </StyledMenuItem>
            {/* <StyledMenuItem onClick={() => setIsGDPRSidePanelOpened(true)} disableGutters>
              <GDPRIcon widthInPx={16} heightInPx={20} />
              <MenuText>
                <FormattedMessage id={userCardPopoverIntl('GDPR')} />
              </MenuText>
            </StyledMenuItem> */}
            <StyledMenuItem onClick={logOut} disableGutters>
              <StyledLogOutIcon />
              <DisconnectText>
                <FormattedMessage id={userCardPopoverIntl('disconnect')} />
              </DisconnectText>
            </StyledMenuItem>
          </StyledMenuList>
        </MenuContainer>
      </Popover>
      <HelpAndContactSidePanel
        onClose={() => setIsHelpAndContactSidePanelOpened(false)}
        isOpen={isHelpAndContactSidePanelOpened}
      />
      <GDPRSidePanel
        onClose={() => setIsGDPRSidePanelOpened(false)}
        isOpen={isGDPRSidePanelOpened}
      />
    </>
  );
};
